// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader,
.loader:before,
.loader:after {
  background: #626ed4;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #626ed4;
  text-indent: -9999em;
  margin: 50vh auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.css"],"names":[],"mappings":"AAAA;;;EAGE,mBAAmB;EAEnB,wCAAwC;EACxC,UAAU;EACV,WAAW;AACb;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EAGf,wBAAwB;EAExB,uBAAuB;AACzB;AACA;;EAEE,kBAAkB;EAClB,MAAM;EACN,WAAW;AACb;AACA;EACE,YAAY;EAEZ,uBAAuB;AACzB;AACA;EACE,WAAW;AACb;AAaA;EACE;;;IAGE,eAAe;IACf,WAAW;EACb;EACA;IACE,kBAAkB;IAClB,WAAW;EACb;AACF","sourcesContent":[".loader,\n.loader:before,\n.loader:after {\n  background: #626ed4;\n  -webkit-animation: load1 1s infinite ease-in-out;\n  animation: load1 1s infinite ease-in-out;\n  width: 1em;\n  height: 4em;\n}\n.loader {\n  color: #626ed4;\n  text-indent: -9999em;\n  margin: 50vh auto;\n  position: relative;\n  font-size: 11px;\n  -webkit-transform: translateZ(0);\n  -ms-transform: translateZ(0);\n  transform: translateZ(0);\n  -webkit-animation-delay: -0.16s;\n  animation-delay: -0.16s;\n}\n.loader:before,\n.loader:after {\n  position: absolute;\n  top: 0;\n  content: '';\n}\n.loader:before {\n  left: -1.5em;\n  -webkit-animation-delay: -0.32s;\n  animation-delay: -0.32s;\n}\n.loader:after {\n  left: 1.5em;\n}\n@-webkit-keyframes load1 {\n  0%,\n  80%,\n  100% {\n    box-shadow: 0 0;\n    height: 4em;\n  }\n  40% {\n    box-shadow: 0 -2em;\n    height: 5em;\n  }\n}\n@keyframes load1 {\n  0%,\n  80%,\n  100% {\n    box-shadow: 0 0;\n    height: 4em;\n  }\n  40% {\n    box-shadow: 0 -2em;\n    height: 5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
