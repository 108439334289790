// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__main {
  /* position: relative; */
}

.page-title-wrapper {
  font-weight: 600;
  font-size: 30px;
  line-height: 43px;
  color: #000000;
  margin-bottom: 17px;
}

button:disabled,
input:disabled,
select:disabled {
  opacity: 0.8;
}

input[type="checkbox"] {
  cursor: pointer;
}

.blur_out_elem {
  opacity: 0.3 !important;
}

textarea {
  resize: none;
}

.dropdown-hover:hover > .dropdown-menu {
  display: inline-block;
}

.dropdown-hover > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

/* Admin Sidebar Link Wrapper */
.sidebar_link_wrapper {
  display: flex !important;
  align-items: center;
  column-gap: 12px !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;;;EAGE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,6CAA6C;EAC7C,oBAAoB;AACtB;;AAEA,+BAA+B;AAC/B;EACE,wBAAwB;EACxB,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".app__main {\n  /* position: relative; */\n}\n\n.page-title-wrapper {\n  font-weight: 600;\n  font-size: 30px;\n  line-height: 43px;\n  color: #000000;\n  margin-bottom: 17px;\n}\n\nbutton:disabled,\ninput:disabled,\nselect:disabled {\n  opacity: 0.8;\n}\n\ninput[type=\"checkbox\"] {\n  cursor: pointer;\n}\n\n.blur_out_elem {\n  opacity: 0.3 !important;\n}\n\ntextarea {\n  resize: none;\n}\n\n.dropdown-hover:hover > .dropdown-menu {\n  display: inline-block;\n}\n\n.dropdown-hover > .dropdown-toggle:active {\n  /*Without this, clicking will make it sticky*/\n  pointer-events: none;\n}\n\n/* Admin Sidebar Link Wrapper */\n.sidebar_link_wrapper {\n  display: flex !important;\n  align-items: center;\n  column-gap: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
