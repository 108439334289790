// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.metadata-form-wrapper input:required:focus,
.metadata-form-wrapper select:required:focus {
  outline: none;
  border: 1px solid red !important;
}
`, "",{"version":3,"sources":["webpack://./src/styles/UnindexedFiles.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,gCAAgC;AAClC","sourcesContent":[".metadata-form-wrapper input:required:focus,\n.metadata-form-wrapper select:required:focus {\n  outline: none;\n  border: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
