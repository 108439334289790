// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import 'react-toastify/dist/ReactToastify.css'; */

.app-alert .alert {
    width: 20%;
    position: absolute;
    right: 1rem;
    top: 4rem;
    z-index: 393939;
    font-size: .85em;
    border-radius: 0;
    font-weight: 700;
    padding: 0.5rem;
    /* top: 1rem; */
}

.app-alert .bg-gray {
    background: #e3e3e3cc;
}


/* .alert-success {
    background-color: #16e4b0;
}

.alert-warning {
    background-color: #f6c065;
}

.alert-error {
    background-color: #fa1616;
}

.alert-info {
    background-color: #87a4f5;
} */

.app-alert .alert-success {
    background-color: #fff;
    color: #06946b !important;
    border: 3px solid;
}

.app-alert .alert-warning {
    background-color: #fff;
    color: #dc7308db !important;
    border: 3px solid;
}

.app-alert .alert-error {
    background-color: #fff;
    border: 3px solid;
}

.app-alert .alert-info {
    background-color: #fff;
    color: #593cc1 !important;
    border: 3px solid;
}

@media screen and (max-width: 768px) {
    .app-alert .alert {
        width: 70%;
        position: absolute;
        right: 1rem;
        top: 3rem;
        z-index: 393939;
        font-size: .85em;
        border-radius: 0;
        font-weight: 700;
        padding: 0.5rem;
        /* top: 1rem; */
    }
}`, "",{"version":3,"sources":["webpack://./src/shared/AppAlert.css"],"names":[],"mappings":"AAAA,qDAAqD;;AAErD;IACI,UAAU;IACV,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;;AAGA;;;;;;;;;;;;;;GAcG;;AAEH;IACI,sBAAsB;IACtB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;IAC3B,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI;QACI,UAAU;QACV,kBAAkB;QAClB,WAAW;QACX,SAAS;QACT,eAAe;QACf,gBAAgB;QAChB,gBAAgB;QAChB,gBAAgB;QAChB,eAAe;QACf,eAAe;IACnB;AACJ","sourcesContent":["/* @import 'react-toastify/dist/ReactToastify.css'; */\n\n.app-alert .alert {\n    width: 20%;\n    position: absolute;\n    right: 1rem;\n    top: 4rem;\n    z-index: 393939;\n    font-size: .85em;\n    border-radius: 0;\n    font-weight: 700;\n    padding: 0.5rem;\n    /* top: 1rem; */\n}\n\n.app-alert .bg-gray {\n    background: #e3e3e3cc;\n}\n\n\n/* .alert-success {\n    background-color: #16e4b0;\n}\n\n.alert-warning {\n    background-color: #f6c065;\n}\n\n.alert-error {\n    background-color: #fa1616;\n}\n\n.alert-info {\n    background-color: #87a4f5;\n} */\n\n.app-alert .alert-success {\n    background-color: #fff;\n    color: #06946b !important;\n    border: 3px solid;\n}\n\n.app-alert .alert-warning {\n    background-color: #fff;\n    color: #dc7308db !important;\n    border: 3px solid;\n}\n\n.app-alert .alert-error {\n    background-color: #fff;\n    border: 3px solid;\n}\n\n.app-alert .alert-info {\n    background-color: #fff;\n    color: #593cc1 !important;\n    border: 3px solid;\n}\n\n@media screen and (max-width: 768px) {\n    .app-alert .alert {\n        width: 70%;\n        position: absolute;\n        right: 1rem;\n        top: 3rem;\n        z-index: 393939;\n        font-size: .85em;\n        border-radius: 0;\n        font-weight: 700;\n        padding: 0.5rem;\n        /* top: 1rem; */\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
