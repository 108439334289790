// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

/* Table Top */
.primary-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

.primary-table--top-wrapper .table-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}

.primary-table--top-wrapper .table-top-button button {
  width: 138px;
  height: 45px;
  outline: none;
  border-radius: 10px;
}

.primary-table {
  background-color: white;
  padding: 15px 25px 0;
}

.primary-table tbody tr {
  vertical-align: middle;
}

.below-table-loader-wrapper {
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Table.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA,cAAc;AACd;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".primary-table-container {\n  overflow-x: auto;\n  overflow-y: hidden;\n}\n\n/* Table Top */\n.primary-table--top-wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 13px;\n}\n\n.primary-table--top-wrapper .table-title {\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 40px;\n  color: #000000;\n}\n\n.primary-table--top-wrapper .table-top-button button {\n  width: 138px;\n  height: 45px;\n  outline: none;\n  border-radius: 10px;\n}\n\n.primary-table {\n  background-color: white;\n  padding: 15px 25px 0;\n}\n\n.primary-table tbody tr {\n  vertical-align: middle;\n}\n\n.below-table-loader-wrapper {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
