import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const UserContext = createContext(null);
function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const userDetails = useSelector(
    (state) => state.auth.loggedInUserDetails?.data?.data
  );

  useEffect(() => {
    if (userDetails) setUser(userDetails);
  }, [userDetails]);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;
