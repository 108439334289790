import React from "react";
import Loading from "components/Loading/loading";
import AppAlert from "./shared/AppAlert";
import "./styles/Dashboard.css"
import "./styles/UnindexedFiles.css"
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "./App.css"
import "./styles/Table.css"
import "./styles/Modals.css"
import "./styles/Flatpickr.css"
import "./styles/AntDesignTable.css"
import "flatpickr/dist/themes/material_blue.css"
import 'react-loading-skeleton/dist/skeleton.css'
import UserContextProvider from "components/contexts/UserContext";
import store from "store/store";
// Styles
// Importing CSS Files

import "./assets/css/bootstrap.min.css"
import "./assets/css/app.min.css"
import "./assets/css/icons.min.css"


const AppRouter = React.lazy(() => import("../src/shared/AppRouter"));

function App() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Provider store={store}>
        <Router>
          <UserContextProvider>
            <AppAlert />
            <AppRouter />
          </UserContextProvider>
        </Router>
      </Provider>
    </React.Suspense >
  );
}

export default App;
