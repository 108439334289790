// Function will execute on click of button
export const downloadFileToLocal = (url, fileName) => {
    // Create a hidden anchor element
    const anchor = document.createElement('a');
    anchor.href = url;

    //     // Set attribute to open a new tab for download
    anchor.setAttribute("target", "_blank")

    // Set the download attribute with a default filename
    anchor.download = fileName || 'downloaded_file';

    // Append the anchor to the body
    document.body.appendChild(anchor);

    // Programmatically click the anchor to trigger the download
    anchor.click();

    // Remove the anchor from the document
    document.body.removeChild(anchor);
}