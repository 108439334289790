// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Primary Modal Styles */
.primary_modal_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primary_modal_header_wrapper .modal_title {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
}

/* Modal Contents */
.add_metadata_modal_container .modal-content,
.create_metadata_modal_container .modal-content {
  padding: 1rem;
  border-radius: 10px !important;
  min-height: 150px;
  width: 600px;
}

.primary_modal_action_btns_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 50px;
}

.primary_modal_action_btns_wrapper button {
  width: 120px;
  height: 30px;
  font-size: 14px;
}

/* Workflow  and steps modals*/
.workflow__step_modal_container.modal-dialog {
  max-width: 900px !important;
}

.workflow__step_modal_container .modal-content {
  padding: 1rem;
  border-radius: 10px !important;
  min-height: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Modals.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA,mBAAmB;AACnB;;EAEE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA,8BAA8B;AAC9B;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,iBAAiB;AACnB","sourcesContent":["/* Primary Modal Styles */\n.primary_modal_header_wrapper {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.primary_modal_header_wrapper .modal_title {\n  font-size: 17px;\n  font-weight: 500;\n  color: #000000;\n}\n\n/* Modal Contents */\n.add_metadata_modal_container .modal-content,\n.create_metadata_modal_container .modal-content {\n  padding: 1rem;\n  border-radius: 10px !important;\n  min-height: 150px;\n  width: 600px;\n}\n\n.primary_modal_action_btns_wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  column-gap: 10px;\n  margin-top: 50px;\n}\n\n.primary_modal_action_btns_wrapper button {\n  width: 120px;\n  height: 30px;\n  font-size: 14px;\n}\n\n/* Workflow  and steps modals*/\n.workflow__step_modal_container.modal-dialog {\n  max-width: 900px !important;\n}\n\n.workflow__step_modal_container .modal-content {\n  padding: 1rem;\n  border-radius: 10px !important;\n  min-height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
