// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button {
  outline: none !important;
}

.dashboard-card-body {
  min-width: 680px;
  padding: 0 1.25rem;
  padding-top: 7px;
  overflow-y: hidden;
}

.dashboard-white-card::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.dashboard-white-card::-webkit-scrollbar {
  height: 7px;
  background-color: #c4c4c4;
}

.dashboard-white-card::-webkit-scrollbar-thumb {
  background-color: #656565;
}

.dashboard-white-card::-webkit-scrollbar-track,
.dashboard-white-card::-webkit-scrollbar,
.dashboard-white-card::-webkit-scrollbar-thumb {
  border-radius: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;;EAGE,kBAAkB;AACpB","sourcesContent":["button {\n  outline: none !important;\n}\n\n.dashboard-card-body {\n  min-width: 680px;\n  padding: 0 1.25rem;\n  padding-top: 7px;\n  overflow-y: hidden;\n}\n\n.dashboard-white-card::-webkit-scrollbar-track {\n  background-color: #c4c4c4;\n}\n\n.dashboard-white-card::-webkit-scrollbar {\n  height: 7px;\n  background-color: #c4c4c4;\n}\n\n.dashboard-white-card::-webkit-scrollbar-thumb {\n  background-color: #656565;\n}\n\n.dashboard-white-card::-webkit-scrollbar-track,\n.dashboard-white-card::-webkit-scrollbar,\n.dashboard-white-card::-webkit-scrollbar-thumb {\n  border-radius: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
