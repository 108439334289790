import axiosService from "services/axios.service";
import { showErrorMessage, } from "../notification";
import { handleThrowSuccess } from "utils/alerts";


//initial values
const Initial_State = {
    companyDetails: {},
    companyDetailsLoading: false,
};

// Action types
const COMPANY_DETAILS = "COMPANY_DETAILS";
const COMPANY_DETAILS_LOADING = "COMPANY_DETAILS_LOADING";
const UPLOAD_LOADING = "UPLOAD_LOADING";
const ERROR = "ERROR";


// Reducer
export default function reducer(state = Initial_State, action = {}) {
    switch (action.type) {
        case COMPANY_DETAILS:
            return {
                ...state,
                error: null,
                companyDetailsLoading: false,
                companyDetails: action?.payload,
            };
        case COMPANY_DETAILS_LOADING:
            return {
                ...state,
                companyDetailsLoading: action.payload,
                error: null,
            };
        case UPLOAD_LOADING:
            return {
                ...state,
                uploadLoading: action.payload,
                error: null,
            };
        case ERROR:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
}

// Action Creators
export function isRequestLoading(data) {
    return {
        type: COMPANY_DETAILS_LOADING,
        payload: data,
    };
}

export function isUploadLoading(data) {
    return {
        type: UPLOAD_LOADING,
        payload: data,
    };
}

export function isError(data) {
    return {
        type: ERROR,
        payload: data,
    };
}

// Company Details Response
export function onCompanyDetailsLoad(data) {
    return {
        type: COMPANY_DETAILS,
        payload: data,
    };
}



// Actions
// Get Company Details
export function loadCompanyDetails() {
    return (dispatch) => {
        dispatch(isRequestLoading(true));
        return axiosService.Setup.loadCompanyDetails().then(
            (response) => {
                dispatch(onCompanyDetailsLoad(response.data));
                dispatch(isRequestLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to load company details!",
                    })
                );
                dispatch(isRequestLoading(false));
            }
        );
    };
}

// Upload Company Logo
export function uploadCompanyLogo(formData) {
    return (dispatch) => {
        dispatch(isUploadLoading(true));
        return axiosService.Setup.uploadCompanyLogo(formData).then(
            (response) => {
                handleThrowSuccess(dispatch, "Company logo uploaded successfully!");
                dispatch(isUploadLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to upload logo image!",
                    })
                );
                dispatch(isUploadLoading(false));
            }
        );
    };
}

// Upload COmpany Background
export function uploadCompanyBackground(formData) {
    return (dispatch) => {
        dispatch(isUploadLoading(true));
        return axiosService.Setup.uploadCompanyBackground(formData).then(
            (response) => {
                handleThrowSuccess(dispatch, "Background image uploaded successfully!");
                dispatch(isUploadLoading(false));
            },
            (error) => {
                dispatch(
                    showErrorMessage({
                        type: "error",
                        message: error,
                        title: "Failed to upload background image!",
                    })
                );
                dispatch(isUploadLoading(false));
            }
        );
    };
}